import React from "react"
import { Link } from "gatsby"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"

const BookPagination = ({
  previousTitle,
  previousSlug,
  nextTitle,
  nextSlug,
  parentSlug
}) => (
  <Row className="book-pagination clearfix w-100">
    <Col>
      {previousSlug ? (
        <Link to={previousSlug} className="page-previous float-left">
          &lt; {previousTitle}
        </Link>
      ) : null}
      {parentSlug ? (
        <Link to={parentSlug} className="page-parent">
          Up
        </Link>
      ) : null}
      {nextSlug ? (
        <Link to={nextSlug} className="page-next float-right">
          {nextTitle} &gt;
        </Link>
      ) : null}
    </Col>
  </Row>
)

export default BookPagination
