import React from "react"
import { Link } from "gatsby"

const BookNavigation = ({ id, nodes }) => {
  nodes.shift()

  return (
    <nav>
      <ul id={id} className="menu book-navigation">
        {nodes.map(({ node }, key) => (
          <li className="menu-link" key={key}>
            <Link to={node.frontmatter.slug} activeClassName="active">
              {node.frontmatter.title}
            </Link>
          </li>
        ))}
      </ul>
    </nav>
  )
}

export default BookNavigation
