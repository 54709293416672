import React from "react"
import { Link } from "gatsby"
import PdfIcon from "./pdf-icon"

const RelatedMediaThumbnail = ({ media }) => {
  if (media !== null) {
    let output
    if (media.frontmatter.type === "image") {
      output = (
        <img
          // prettier-ignore
          className="img-center"
          src={`${media.frontmatter.path}/${media.frontmatter.name}-relatedinfo.${media.frontmatter.ext}`}
          alt={`${media.frontmatter.name} thumbnail`}
        />
      )
    }
    if (media.frontmatter.type === "document") {
      output = <PdfIcon />
    }
    if (media.frontmatter.type === "video") {
      output = (
        <img
          className="img-center video-thumbnail"
          src={`${media.frontmatter.path}/${media.frontmatter.name}.jpg`}
          alt={`${media.frontmatter.name} thumbnail`}
        />
      )
    }
    return (
      <div
        className="related-media-thumbnail block-contents"
        style={{ marginBottom: `12px` }}
      >
        <Link
          key={media.frontmatter.mid}
          to={`/media/${media.frontmatter.mid}`}
          aria-label={`View ${media.frontmatter.title}`}
        >
          {output}
        </Link>
        <br />
        <cite>{media.frontmatter.title}</cite>
      </div>
    )
  }
  return null
}

const RelatedMedia = ({ relatedMedia }) => {
  if (relatedMedia !== null && relatedMedia.length) {
    return (
      <div id="related-media">
        <h4 className="block-title">Related Media</h4>
        {relatedMedia.map((media, key) => (
          <RelatedMediaThumbnail media={media} key={key} />
        ))}
      </div>
    )
  }
  return null
}

export default RelatedMedia
