import React from "react"
import { graphql } from "gatsby"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Layout from "../components/layout"
import SEO from "../components/seo"
import RelatedMedia from "../components/related-media"
import BookNavigation from "../components/book-navigation"
import VideoHistoryBlock from "../components/VideoHistoryBlock"
import Breadcrumbs from "../components/breadcrumb"
import BookPagination from "../components/book-pagination"

const BookTemplate = ({ data, pageContext }) => {
  const title = data.markdownRemark.frontmatter.title
  const html = data.markdownRemark.html
  const relatedMedia = data.markdownRemark.frontmatter.related_media
  const bookNodes = pageContext.bookNodes
  const crumbs = [
    {
      title: pageContext.parentTitle,
      slug: pageContext.parentSlug
    }
  ]
  if (pageContext.isParent) {
    crumbs.pop()
  }

  return (
    <Layout>
      <SEO title={title} />

      <Row>
        <Col id="page-title" className="col col-12">
          <h1 className="title">{title}</h1>
        </Col>
      </Row>
      <Breadcrumbs crumbs={crumbs} />
      <Row id="content">
        <Col s="12" md="3" className="d-none d-md-block">
          <div id="sidebar-first" className="sidebar sidebar-first">
            <BookNavigation id="book-navigation" nodes={bookNodes} />
          </div>
        </Col>
        <Col s="12" md="6" id="main-content">
          <div dangerouslySetInnerHTML={{ __html: html }} />
        </Col>
        <Col s="12" md="3" className="d-md-none">
          <div id="mobile-sidebar">
            <BookNavigation id="book-navigation-mobile" nodes={bookNodes} />
          </div>
        </Col>
        <Col md="12" lg="3">
          <div id="sidebar-second" className="sidebar">
            <RelatedMedia relatedMedia={relatedMedia} />
            <VideoHistoryBlock />
          </div>
        </Col>
      </Row>
      <BookPagination
        // prettier-ignore
        parentSlug={pageContext.isParent === true ? null : pageContext.parentSlug}
        previousSlug={pageContext.previousSlug}
        previousTitle={pageContext.previousTitle}
        nextTitle={pageContext.nextTitle}
        nextSlug={pageContext.nextSlug}
      />
    </Layout>
  )
}

export const query = graphql`
  query($slug: String!) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      frontmatter {
        title
        slug
        related_media {
          frontmatter {
            mid
            title
            name
            type
            path
            ext
          }
        }
        weight
      }
      html
    }
  }
`

export default BookTemplate
